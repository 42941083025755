import React from 'react';
import { Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { setFromDate, setSearchValues, setToDate } from 'store/ticketFetchSlice';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid, getYear } from 'date-fns';


function FromToDateFilter() {
  const dispatch = useDispatch();
  const fromDate = useSelector(state => state.ticketFetchSlice.searchValues.fromDate?.value || null);
  const toDate = useSelector(state => state.ticketFetchSlice.searchValues.toDate?.value || null);
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

  const handleFromDateChange = (newValue) => {
    if (isValid(newValue) && getYear(newValue) > 2000) {
    dispatch(setFromDate(newValue));
    dispatch(setSearchValues({ fieldName: 'fromDate', value: newValue }));
  }
  };

  const handleToDateChange = (newValue) => {
    if (isValid(newValue) && getYear(newValue) > 2000) {
      dispatch(setToDate(newValue));
      dispatch(setSearchValues({ fieldName: 'toDate', value: newValue }));
    }
  };  

  const clearFromDate = () => {
    dispatch(setSearchValues({ fieldName: 'fromDate', value: null }));
    dispatch(setFromDate(null));
  };

  const clearToDate = () => {
    dispatch(setSearchValues({ fieldName: 'toDate', value: null }));
    dispatch(setToDate(null));

  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" justifyContent="space-between" gap={2}>
        <Box display="flex">
          <DatePicker
            label="Από Ημερομηνία"
            value={fromDate || null}
            onChange={handleFromDateChange}
            format="dd/MM/yyyy"
            size="small"
            disabled={ticketsLoading}
            slotProps={{ textField: { size: 'small' } }}
            />
          {fromDate && (
            <IconButton onClick={clearFromDate} disabled={ticketsLoading}>
              <ClearIcon />
            </IconButton>
          )}
        </Box>
        <Box display="flex">
          <DatePicker
            label="Έως Ημερομηνία"
            value={toDate || null}
            onChange={handleToDateChange}
            format="dd/MM/yyyy"
            slotProps={{ textField: { size: 'small' } }}
            minDate={fromDate}
            disabled={ticketsLoading}
          />
          {toDate && (
            <IconButton onClick={clearToDate} disabled={ticketsLoading}>
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default FromToDateFilter;
