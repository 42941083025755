import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import fetchTickets from "app/Api Calls/GetTickets";
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import {createRequestBody} from "app/main/tickets/Filters/Model/SearchModel";
import {fetchAvailableSourcesApplication, fetchGroupRoles, fetchUsersWithId} from "app/Api Calls/TeamsRequiredApis";
import settingsConfig from "app/configs/settingConfig";
import {jwtService} from "app/auth/jwtService";

export const fetchTicketsAsync = createAsyncThunk(
    "tickets/fetchTickets",
    async ({ListTicketCurrentPage, size, searchParams}) => {
        const requestBody = createRequestBody(searchParams);
        const fetchedTickets = await fetchTickets({
            ListTicketCurrentPage,
            size,
            requestBody,
        });
        return fetchedTickets;
    }
);

export const searchTicketsAsync = createAsyncThunk(
    'tickets/search',
    async ({ListTicketCurrentPage, size, searchParams,additionalParams}, {rejectWithValue}) => {
        try {
            const requestBody = createRequestBody(searchParams);
            const fetchedTickets = await searchTickets(
                ListTicketCurrentPage,
                size,
                requestBody,
                additionalParams
            );
            return fetchedTickets;
        } catch (error) {
            if (error.response?.status === 401) {
                return rejectWithValue('Rejected with 401');
            }
            return rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);


export const searchUsersAsync = createAsyncThunk(
    "users/search",
    async ({searchPayload, page, size}, {rejectWithValue}) => {
        try {
            const response = await fetchUsersWithId(page, size, searchPayload);
            const users = response.elements;
            return {
                users: users,
                ListUserCurrentPage: page,
                size: size
            };
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchSourceApplicationsAsync = createAsyncThunk(
    "sourceApplications/fetch",
    async (_, {rejectWithValue}) => {
        try {
            const data = await fetchAvailableSourcesApplication();
            return data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : "An error occurred");
        }
    }
);

export const fetchGroupRolesAsync = createAsyncThunk(
    "roles/fetchGroupRoles",
    async (_, {rejectWithValue}) => {
        try {
            const response = await fetchGroupRoles();
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);


export const initialState = {
    items: [],
    status: "idle",
    error: null,
    ListTicketCurrentPage: 0,
    ListTicketTotalPages: 1,
    size: 10,
    totalTickets: 0,
    fromDate: null,
    toDate: null,
    beneficiary: null,
    ticketNumber: null,
    sourceApplications: null,
    selectedTeamIds: [],
    carrier: null,
    level: null,
    selectedTicketStatus: [
        {
            id: 4,
            shortName: "Open",
            name: "Ανοιχτό",
            isActive: true
        },
        {
            id: 8,
            shortName: "Reopen",
            name: "Reopen",
            isActive: true
        }
    ],
    searchParams: {},
    searchValues: {},
    selectedUserId: null,
    userCarriers: [],
    teamList: [],
    allUsers: [],
    inputValue: "",
    fetchedUsers: [],
    beneficiaryToFilterId: null,
    filterBasedCurrentPage: 0,
    previousSearchParams: null,
    selectedCheckboxes: [],
    selectedRole: '',
    canEditFlag: {},
    fetchAfterMultipleAssignments: false,
    allUsersRoles: [],
    beneficiaryModalPage: 0,
    directAssignmentSelectedUser: null,
    directAssignmentInputValue: "",
    selectedSourceApplication: null,
    orderDto: [
        {
            orderByColumn: "createdAt",
            ordrerOrientation: "desc"
        },
        {
            orderByColumn: "updatedAt",
            ordrerOrientation: "desc"
        },
    ],
    isSupervisor: false,
    groupRoles: [],
    isFetchingGroupRoles: false,
    selectedGroupRoleId: null,
    assignment: {
        assignToMe: false,
        assignToTeam: false
    },
    ticketsLoading: false
};


const ticketFetchSlice = createSlice({
    name: "ticketFetch",
    initialState,
    reducers: {
        setFetchAfterMultipleAssignments: (state, action) => {
            state.fetchAfterMultipleAssignments = action.payload;
        },
        setSelectedGroupRoleId: (state, action) => {
            state.selectedGroupRoleId = action.payload;
        },
        setSelectedTicketStatus: (state, action) => {
            state.selectedTicketStatus = action.payload;
        },
        setAssignment: (state, action) => {
            state.assignment = { ...state.assignment, ...action.payload };
        },
        setBeneficiaryModalPage: (state, action) => {
            state.beneficiaryModalPage = action.payload;
        },
        setListTicketCurrentPage: (state, action) => {
            state.ListTicketCurrentPage = action.payload;
        },
        setSelectedSourceApplication: (state, action) => {
            state.selectedSourceApplication = action.payload;
        },
        toggleIsSupervisor: (state) => {
            state.isSupervisor = !state.isSupervisor;
        },
        setCanEditFlag: (state, action) => {
            const {ticketId, flag} = action.payload;
            state.canEditFlag[ticketId] = flag;
        },
        setFromDate: (state, action) => {
            state.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.toDate = action.payload;
        },
        setCarrier: (state, action) => {
            state.carrier = action.payload;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
        },
        setSearchParams: (state, action) => {
            state.searchParams = action.payload;
        },
        setTicketNumber: (state, action) => {
            state.ticketNumber = action.payload;
        },
        setSelectedTeamIds: (state, action) => {
            state.selectedTeamIds = action.payload;
        },
        setSelectedUserId: (state, action) => {
            state.selectedUserId = action.payload;
        },
        setInputValue: (state, action) => {
            state.inputValue = action.payload;
        },
        setFetchedUsers: (state, action) => {
            state.fetchedUsers = action.payload;
        },
        setSize: (state, action) => {
            state.size = action.payload;
        },
        setBeneficiaryToFilterId: (state, action) => {
            state.beneficiaryToFilterId = action.payload;
        },
        setFilterBasedCurrentPage: (state, action) => {
            state.filterBasedCurrentPage = action.payload;
        },
        setPreviousSearchParams: (state, action) => {
            state.previousSearchParams = action.payload;
        },
        setSelectedCheckboxes: (state, action) => {
            state.selectedCheckboxes = action.payload;
        },
        setAllUserRoles: (state, action) => {
            state.allUsersRoles = action.payload;
        },
        setSourceApplication: (state, action) => {
            state.sourceApplication = action.payload;
        },
        setUserCarriers: (state, action) => {
            state.userCarriers = action.payload;
        },
        setTeamList: (state, action) => {
            state.teamList = action.payload;
        },
        setSelectedUserForDirectAssignment: (state, action) => {
            state.directAssignmentSelectedUser = action.payload;
        },
        setDirectAssignmentInputValue: (state, action) => {
            state.directAssignmentInputValue = action.payload;
        },
        setOrderBy: (state, action) => {
            state.orderDto = action.payload;

        },
        setSearchValues: (state, action) => {
            const {fieldName, searchType, value} = action.payload;
            if (!state.searchValues[fieldName]) {
                state.searchValues[fieldName] = {};
            }
            if (searchType) {
                state.searchValues[fieldName].searchType = searchType;
            } else if (fieldName === "beneficiary" && searchType === "") {
                state.searchValues[fieldName].searchType = null;
            }
            state.searchValues[fieldName].value = value;
        },

        resetFilters: (state) => {
            state.fromDate = null;
            state.toDate = null;
            state.ticketNumber = null;
            state.beneficiaryToFilterId = null;
            state.searchValues = {};
            state.selectedUserId = null;
            state.searchValues.beneficiary = {
                searchType: "",
                value: "",
            };
            state.selectedTicketStatus = [{
                id: 4,
                shortName: "Open",
                name: "Ανοιχτό",
                isActive: true
            },
                {
                    id: 8,
                    shortName: "Reopen",
                    name: "Reopen",
                    isActive: true
                }];
            state.selectedRole = '';
            state.searchParams = {};
            state.inputValue = "";
            state.searchType = {};
            state.ListTicketCurrentPage = 0;
            state.ListTicketTotalPages = 1;
            state.previousSearchParams = null;
            state.carrier = null;
            state.level = null;
            state.selectedSourceApplication = null;
            state.selectedTeamIds = [];
        },
        setSelectedRole: (state, action) => {
            state.selectedRole = action.payload;
        },
        setAssignInitialState: (state, action) => {
            state.assignToMe = action.payload.assignToMe;
            state.assignToTeam = action.payload.assignToTeam;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTicketsAsync.pending, (state) => {
                state.status = "loading";
                state.ticketsLoading = true;
            })
            .addCase(fetchTicketsAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.items = action.payload.elements;
                state.ListTicketTotalPages = action.payload.totalPages;
                state.shouldRefetch = false;
                state.totalTickets = action.payload.totalElements;
                state.fetchAfterMultipleAssignments = false;
                state.ticketsLoading = false;
            })
            .addCase(fetchTicketsAsync.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.ticketsLoading = false;
            })
            .addCase(searchTicketsAsync.pending, (state) => {
                state.status = "loading";
                state.ticketsLoading = true;
            })
            .addCase(searchTicketsAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.items = action.payload.elements;
                state.ListTicketTotalPages = action.payload.totalPages;
                state.totalTickets = action.payload.totalElements;
                state.fetchAfterMultipleAssignments = false;
                state.ticketsLoading = false;
            })
            .addCase(searchTicketsAsync.rejected, (state, action) => {
                if (action.payload === 'Rejected with 401') {
                    state.status = "unauthorized";
                    state.items = [];
                    state.ticketsLoading = false;
                } else {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.ticketsLoading = false;
                }
            })
            .addCase(fetchSourceApplicationsAsync.fulfilled, (state, action) => {
                state.sourceApplications = action.payload;
            })
            .addCase(fetchSourceApplicationsAsync.rejected, (state, action) => {
                // Optionally, handle an error state for failed source applications loading
                state.error = action.error.message;
            })

            .addCase(searchUsersAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(searchUsersAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.allUsers = action.payload.users;
                state.ListUserTotalPages = action.payload.totalPages;
            })

            .addCase(searchUsersAsync.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(fetchGroupRolesAsync.pending, (state) => {
                state.isFetchingGroupRoles = true;
            })
            .addCase(fetchGroupRolesAsync.fulfilled, (state, action) => {
                state.groupRoles = action.payload;
                state.isFetchingGroupRoles = false;
            })
            .addCase(fetchGroupRolesAsync.rejected, (state, action) => {
                state.isFetchingGroupRoles = false;
                // Optionally, handle an error state
                state.error = action.error.message;
            });
    },
});

export const {
    setSelectedGroupRoleId,
    setListTicketCurrentPage,
    setFromDate,
    setToDate,
    setSearchParams,
    setTicketNumber,
    setBeneficiaryToFilterId,
    setSearchValues,
    resetFilters,
    setSelectedUserId,
    setPreviousSearchParams,
    setFilterBasedCurrentPage,
    setFetchedUsers,
    setInputValue,
    setSelectedCheckboxes,
    setSelectedRole,
    setSize,
    setFetchAfterMultipleAssignments,
    setAllUserRoles,
    setCanEditFlag,
    setLevel,
    setCarrier,
    setBeneficiaryModalPage,
    setSelectedUserForDirectAssignment,
    setDirectAssignmentInputValue,
    setSelectedTicketStatus,
    setSourceApplication,
    setSelectedSourceApplication,
    setSelectedTeamIds,
    setUserCarriers, setTeamList,
    setOrderBy,
    toggleIsSupervisor,
    setAssignToMe,
    setAssignToTeam,
    setAssignInitialState,
    setAssignment
} = ticketFetchSlice.actions;

export default ticketFetchSlice.reducer;
