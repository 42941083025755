import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { validateAFM, validateAMKA, validateMobile, validatePhone, validateSurname } from "app/main/Fields/ValidateFields";
import { searchBeneficiaries } from "app/Api Calls/FiltersList";
import SearchForm from "./SearchForm";
import BeneficiaryPagination from "./BeneficiaryPagination";
import BeneficiariesTable from "app/main/Panels/BeneficiaryPanel/BeneficiariesTabel";

function BeneficiaryInput() {
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState("");
    const [error, setError] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [noResultsMessage, setNoResultsMessage] = useState("");

    // Pagination state
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(100);

    useEffect(() => {
        if (searchClicked) {
            searchBeneficiariesHandler();
        }
    }, [page, size]);

    const handleChange = (event) => {
        setSearch(event.target.value);
        setNoResultsMessage("");
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setError(false);
        setNoResultsMessage("");
    };

    const searchBeneficiariesHandler = async () => {
        if (searchType === "" || search === "") {
            setError(true);
            setSearchClicked(true);
            return;
        }

        setError(false);
        setSearchClicked(true);
        setLoading(true);

        const result = await searchBeneficiaries(searchType, search, page, size);

        if (result && result.elements.length > 0) {
            setBeneficiaries(result.elements);
            setTotalPages(result.totalPages);
            setTotalElements(result.totalElements);
            setNoResultsMessage("");
        } else {
            setBeneficiaries([]);
            setTotalPages(0);
            setTotalElements(0);
            setNoResultsMessage(`Το φίλτρο αναζήτησης με ${searchType} ${search} δεν έφερε αποτελέσματα.`);
        }

        setLoading(false);
    };

    const handleSearch = () => {
        setPage(0);
        searchBeneficiariesHandler();
    };

    const handleClearSearch = () => {
        setSearchType("");
        setSearch("");
        setError(false);
        setSearchClicked(false);
        setBeneficiaries([]);
        setPage(0);
        setTotalPages(0);
        setTotalElements(0);
        setNoResultsMessage("");
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Function to refresh data with the current filters, pagination, etc.
    const refreshData = async () => {
        await searchBeneficiariesHandler();
    };

    const getHelperText = () => {
        if (searchClicked && !searchType) {
            return "Επιλέξτε το κριτήριο αναζήτησης";
        }

        if (searchClicked && !search) {
            return "Το πεδίο είναι κενό";
        }

        switch (searchType) {
            case "ΑΦΜ":
                return validateAFM(search);
            case "ΑΜΚΑ":
                return validateAMKA(search);
            case "Επώνυμο":
                return validateSurname(search);
            case "Τηλέφωνο":
                return validatePhone(search);
            case "Κινητό":
                return validateMobile(search);
            default:
                return "";
        }
    };

    const isSearchDisabled = !search || getHelperText() !== null;

    return (
        <Box display="flex" flexDirection="column">
            <SearchForm
                search={search}
                searchType={searchType}
                error={error}
                loading={loading}
                isSearchDisabled={isSearchDisabled}
                handleChange={handleChange}
                handleSearchTypeChange={handleSearchTypeChange}
                handleSearch={handleSearch}
                handleClearSearch={handleClearSearch}
                getHelperText={getHelperText}
            />
            {noResultsMessage && (
                <Typography color="error" variant="body2" align="center" marginBottom={2}>
                    {noResultsMessage}
                </Typography>
            )}
            <BeneficiariesTable beneficiaries={beneficiaries} refreshData={refreshData} loading={loading} />
            <BeneficiaryPagination
                totalElements={totalElements}
                page={page}
                size={size}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
            />
        </Box>
    );
}

export default BeneficiaryInput;
