import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";

function CustomTextField({
                           className = "",
                           validator,
                           action,
                           value,
                           isRequired = false,
                           disabled = false,
                           ...props
                         }) {
  const [error, setError] = useState(null);
  const [internalValue, setInternalValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    const errorMessage = value ? validator(value) : null;
    setError(errorMessage);
    setInternalValue(value);

    dispatch(
        action({
          name: props.name,
          value,
          isValid: value ? !errorMessage : false,
        })
    );
  };

  return (
      <TextField
          disabled={disabled}
          {...props}
          onChange={handleChange}
          error={!!error}
          value={internalValue}
          helperText={error}
          FormHelperTextProps={{
            sx: {
              color: "red",
              fontSize: "13px",
            },
          }}
          InputLabelProps={{ sx: { fontSize: "13px" } }}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              fontSize: '13px',
              padding: '10px',
              backgroundColor: 'white',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
            }
          }}
      />
  );
}

export default CustomTextField;
