import {useState, useMemo, useEffect, useRef} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    Button,
    Box,
    Typography,
    Avatar,
    CircularProgress,
    Tooltip,
    Paper,
} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import MaterialPagination from "app/main/Reusable Components/MaterialPagination";
import {
    setCanEditFlag,
    setListTicketCurrentPage, setOrderBy, setSelectedCheckboxes, setSize,
} from "store/ticketFetchSlice";
import {showMessage} from "store/messageSlice";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import {jwtService} from "app/auth/jwtService";
import getFlagColor from "app/main/Reusable Components/Ticket Reusable/FlagsColors";
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import settingsConfig from "app/configs/settingConfig";
import {setUserGroupId, setUserRoleId} from "store/userSlice";

function TicketList() {
    const dispatch = useDispatch();
    const tableTopRef = useRef(null);
    const ListTicketCurrentPage = useSelector(
        (state) => state.ticketFetchSlice.ListTicketCurrentPage
    );
    const ListTicketTotalPages = useSelector(
        (state) => state.ticketFetchSlice.ListTicketTotalPages
    );
    const userInfo = jwtService.getUser();

    const isUserTeamLeaderOrSupervisor =
        settingsConfig.roles.supervisor.includes(userInfo?.roleName) ||
        settingsConfig.roles.teamLeader.includes(userInfo?.roleName);
    const {
        items: tickets,
        size,
        status,
        totalTickets,
        canEditFlag,
    } = useSelector((state) => state.ticketFetchSlice);
    const [sortConfig, setSortConfig] = useState({key: null, direction: null});
    const selectedCheckboxes = useSelector((state) => state.ticketFetchSlice.selectedCheckboxes);
    const navigate = useNavigate();
    const orderBy = useSelector((state) => state.ticketFetchSlice.orderDto);
    const [tempSortCriteria, setTempSortCriteria] = useState([]);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                setIsCtrlPressed(true);
            }
        };

        const handleKeyUp = () => {
            setIsCtrlPressed(false);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (tickets) {
            setTicketsEditFlag();
        }
    }, [tickets]);

    // useEffect(() => {
    //     let dispatchPending = false;
    //
    //     const handleKeyDown = (event) => {
    //         if (event.ctrlKey || event.metaKey) {
    //             console.log("Ctrl/Cmd Pressed");
    //             isCtrlPressedRef.current = true;
    //         }
    //     };
    //
    //     const handleKeyUp = (event) => {
    //         console.log("Key Up Event:", event.key);
    //         if (!event.ctrlKey && !event.metaKey && dispatchPending) {
    //             isCtrlPressedRef.current = false;
    //             dispatchPending = false; // Reset the flag since we're about to dispatch
    //             dispatch(setOrderBy(tempSortCriteria));
    //             setTempSortCriteria([]);
    //         }
    //     };
    //
    //     // Listen for changes in tempSortCriteria to determine if a dispatch is pending
    //     const checkAndDispatch = () => {
    //         if (!isCtrlPressedRef.current && tempSortCriteria.length > 0) {
    //             // If there's a change in tempSortCriteria without Ctrl/Cmd being pressed, dispatch immediately
    //             console.log("Immediate Dispatch due to tempSortCriteria change:", tempSortCriteria);
    //             dispatch(setOrderBy(tempSortCriteria));
    //             setTempSortCriteria([]);
    //         } else if (isCtrlPressedRef.current) {
    //             // Mark dispatch as pending if Ctrl/Cmd is pressed; actual dispatch will be on key up
    //             dispatchPending = true;
    //         }
    //     };
    //
    //     // Call checkAndDispatch whenever tempSortCriteria changes
    //     checkAndDispatch();
    //
    //     document.addEventListener('keydown', handleKeyDown);
    //     document.addEventListener('keyup', handleKeyUp);
    //
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //         document.removeEventListener('keyup', handleKeyUp);
    //     };
    // }, [dispatch, tempSortCriteria]);

    const setTicketsEditFlag = () => {
        tickets.forEach((ticket) => {
            let flag = false;

            const isTeamLeader = settingsConfig.roles.teamLeader.includes(userInfo?.roleName);
            const isAgent = settingsConfig.roles.agent.includes(userInfo?.roleName);
            const isAdmin = settingsConfig.roles.admin.includes(userInfo?.roleName);
            const isSupervisor = settingsConfig.roles.supervisor.includes(userInfo?.roleName);
            const isTicketClosed = ticket.ticketStatus.id === 5;

            if (isAdmin) {
                // Admins can edit all tickets
                flag = true;
            } else if (isSupervisor) {
                // Supervisors can edit closed tickets with the same group ID and matching carrier ID
                const ticketGroupId = ticket.assigneeUser.roleCollection && ticket.assigneeUser.roleCollection.length > 0
                    ? ticket.assigneeUser.roleCollection[0].group.id
                    : null;
                const userCarrierIds = userInfo?.carriers?.map(carrier => carrier.id) || [];
                const ticketCarrierId = ticket.thematic?.carrier?.id;

                if ((ticketGroupId === userInfo?.userGroupId) && (ticketCarrierId && userCarrierIds.includes(ticketCarrierId))) {
                    flag = true;
                }
            } else if (isTeamLeader && !isTicketClosed) {
                // Team Leaders can edit tickets not in status 5, within the same group role ID, and in the same team
                const ticketGroupId = ticket.assigneeUser.roleCollection && ticket.assigneeUser.roleCollection.length > 0
                    ? ticket.assigneeUser.roleCollection[0].group.id
                    : null;
                if ((ticketGroupId === userInfo?.userGroupId) && userInfo?.teamIds?.includes(ticket.teamId)) {
                    flag = true;
                }
            } else if (isAgent && !isTicketClosed) {
                // Agents can only edit tickets assigned to them
                if (ticket.assigneeUser.id === userInfo.id) {
                    flag = true;
                }
            }

            dispatch(setCanEditFlag({ticketId: ticket.id, flag}));
        });
    };

    const isCheckboxDisabled = (ticket) => {
        const isTeamLeader = settingsConfig.roles.teamLeader.includes(userInfo?.roleName);
        const isSupervisor = settingsConfig.roles.supervisor.includes(userInfo?.roleName);
        const isTicketClosed = ticket.ticketStatus.id === 5;

        const ticketGroupId = ticket.assigneeUser.roleCollection && ticket.assigneeUser.roleCollection.length > 0
            ? ticket.assigneeUser.roleCollection[0].group.id
            : null;
        const userCarrierIds = userInfo?.carriers?.map(carrier => carrier.id) || [];
        const ticketCarrierId = ticket.thematic?.carrier?.id;

        // If the ticket is not closed
        if (!isTicketClosed) {
            // Team Leaders can edit tickets not in status 5, within the same group role ID, and in the same team
            if (isTeamLeader && ticketGroupId === userInfo?.userGroupId && userInfo?.teamIds?.includes(ticket.teamId)) {
                return false;
            }
        }

        // Supervisors can edit closed tickets with the same group ID and matching carrier ID
        if (isSupervisor && ticketGroupId === userInfo?.userGroupId && (ticketCarrierId && userCarrierIds.includes(ticketCarrierId))) {
            return false;
        }

        // If none of the conditions are met, return true (disabled)
        return true;
    };




// Function to handle double-click event for navigating to a ticket
    const handleDoubleClick = (ticket) => {
        navigate(`/ticket/${ticket.id}`);

    };


    const headCells = [
        {id: 'platform', numeric: false, disablePadding: true, label: 'Πλατφόρμα', disableSorting: true},

        {
            id: "uuid",
            label: "Αριθμός Ticket",
            disableSorting: false,
        },
        {
            id: "createdAt",
            label: "Ημερομηνία",
            disableSorting: false,
        },
        {
            id: "status",
            label: "Κατάσταση",
            disableSorting: true,
        },
        {
            id: "flags",
            label: "Flags",
            disableSorting: true,
        },
        {
            id: "beneficiary",
            label: "Ονοματεπώνυμο Δικαιούχου",
            disableSorting: true,
        },
        {id: "foreas", disableSorting: true, label: "Φορέας"},
        {id: "level", disableSorting: true, label: "Επίπεδο"},
        {
            id: "assignee",
            label: "Ανατεθειμένο",
            disableSorting: true,
        },
        {
            id: "actions",
            label: "Ενέργειες",
            disableSorting: true,
        },
    ];

    // Function to handle click event on individual row selection
    const handleSelectClick = (ticket) => {
        if (ticket.ticketStatus.id == 5) {
            dispatch(showMessage({
                message: "Δεν μπορείτε να επιλέξετε κλειστά tickets",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
            return;
        }
        const selectedIndex = selectedCheckboxes.findIndex((row) => row.id === ticket.id);

        let newSelected = [];

        if (selectedIndex === -1) {
            if (selectedCheckboxes.length < 10) {
                newSelected = newSelected.concat(selectedCheckboxes, {
                    id: ticket.id,
                    uuid: ticket.uuid,
                });
            } else {
                dispatch(showMessage({
                    message: "Το όριο των επιλεγμένων tickets για ανάθεση είναι 10",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                }));
                return;
            }
        } else {
            newSelected = [
                ...selectedCheckboxes.slice(0, selectedIndex),
                ...selectedCheckboxes.slice(selectedIndex + 1),
            ];
        }
        dispatch(setSelectedCheckboxes(newSelected));
    };


    const handleSelectAllClick = () => {
        dispatch(setSelectedCheckboxes([]));
    };


    // Function to get the comparator for sorting
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // Function to compare two values for descending sorting
    function descendingComparator(a, b, orderBy) {
        if (orderBy === "beneficiary") {
            const nameA = a[orderBy]?.lastName.toLowerCase();
            const nameB = b[orderBy]?.lastName.toLowerCase();
            if (nameB < nameA) {
                return -1;
            }
            if (nameB > nameA) {
                return 1;
            }
            return 0;
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }

    // Function to perform stable sort on an array
    function stableSort(array, comparator) {
        if (!Array.isArray(array)) {
            return [];
        }
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    const requestSort = (key) => {
        if (!isCtrlPressed) return;

        const fieldMap = {
            uuid: 'id',
            "Ημερομηνία": 'createdAt',
        };
        const sortBy = fieldMap[key] || key;

        // Check if the current sortBy column already exists in the sort criteria
        const existingCriterionIndex = orderBy.findIndex(c => c.orderByColumn === sortBy);

        if (existingCriterionIndex !== -1) {
            // If the sortBy column exists, toggle its direction
            const currentDirection = orderBy[existingCriterionIndex].ordrerOrientation;
            const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';

            if (sortBy === 'createdAt') {
                // Special case for "Ημερομηνία": Always toggle between 'asc' and 'desc' and add both createdAt and updatedAt
                const updatedOrderBy = [
                    { orderByColumn: 'createdAt', ordrerOrientation: newDirection },
                    { orderByColumn: 'updatedAt', ordrerOrientation: newDirection }
                ];
                dispatch(setOrderBy(updatedOrderBy));
            } else {
                // For other columns, toggle direction or remove if going from 'desc' to 'asc'
                if (currentDirection === 'asc') {
                    // Remove the column from the orderBy array
                    const updatedOrderBy = orderBy.filter((_, index) => index !== existingCriterionIndex);
                    dispatch(setOrderBy(updatedOrderBy));
                } else {
                    // Toggle direction
                    const updatedOrderBy = orderBy.map((criterion, index) =>
                        index === existingCriterionIndex ? { ...criterion, ordrerOrientation: newDirection } : criterion
                    );
                    dispatch(setOrderBy(updatedOrderBy));
                }
            }
        } else {
            // If the column does not exist, determine the direction for the new criterion
            const newDirection = 'desc'; // Default to 'desc' for new columns

            // Special case for "Ημερομηνία": Add both createdAt and updatedAt
            if (sortBy === 'createdAt') {
                const newOrderBy = [
                    { orderByColumn: 'createdAt', ordrerOrientation: newDirection },
                    { orderByColumn: 'updatedAt', ordrerOrientation: newDirection }
                ];
                dispatch(setOrderBy(newOrderBy));
            } else {
                const newOrderBy = [...orderBy, { orderByColumn: sortBy, ordrerOrientation: newDirection }];
                dispatch(setOrderBy(newOrderBy));
            }
        }
    };




    // Memoized sorted rows based on sorting configuration
    const sortedRows = useMemo(() => {
        const comparator = getComparator(sortConfig.direction, sortConfig.key);
        return stableSort(tickets, comparator);
    }, [tickets, sortConfig]);

    const handleSizeChange = (event) => {
        const newSize = event.target.value;
        dispatch(setSize(newSize));
    };

    // Function to handle click event for showing a ticket
    const handleShowTicket = (id) => {
        navigate(`/ticket/${id}`);
    };

    // Function to handle click event for editing a ticket
    const handleEditTicket = (id) => {
        navigate(`/edit-ticket/${id}`);
    };

    const getColumnSortDirection = (columnId) => {
        const fieldMap = {
            uuid: 'id',
            "Ημερομηνία": 'createdAt',
            beneficiary: 'beneficiary'
        };
        const sortBy = fieldMap[columnId] || columnId;

        // Check for both createdAt and updatedAt in the orderBy array
        if (sortBy === 'createdAt') {
            const sortCriterion = orderBy.find(criterion => criterion.orderByColumn === 'createdAt');
            return sortCriterion ? sortCriterion.ordrerOrientation : null;
        }

        const sortCriterion = orderBy.find(criterion => criterion.orderByColumn === sortBy);
        return sortCriterion ? sortCriterion.ordrerOrientation : null;
    };

    return (
        <Box sx={{width: "100%", overflow: "hidden"}}>
            <Box>
                <Typography ref={tableTopRef} variant="h6">Αριθμός Tickets: {totalTickets}</Typography>
                <TableContainer component={Paper} style={{width: "100%", margin: "0 auto"}}>
                    <Table style={{tableLayout: 'fixed'}}>
                        <TableHead sx={{backgroundColor: "#D4DFF5"}}>
                            <TableRow>
                                {isUserTeamLeaderOrSupervisor && (
                                    <TableCell sx={{width: "3%"}}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%"
                                        }}>
                                            <Checkbox
                                                indeterminate={
                                                    selectedCheckboxes.length > 0 &&
                                                    selectedCheckboxes.length < sortedRows.length
                                                }
                                                checked={
                                                    sortedRows.length > 0 &&
                                                    selectedCheckboxes.length === sortedRows.length
                                                }

                                                onChange={handleSelectAllClick}
                                            />
                                        </Box>
                                    </TableCell>
                                )}
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align="center"
                                        sx={{
                                            width: headCell.label === "Ονοματεπώνυμο Δικαιούχου" ? "14%" :
                                                headCell.label === "Αριθμός Ticket" ? "9%" :
                                                    headCell.label === "Φορέας" ? "7%" :
                                                        headCell.label === "Επίπεδο" ? "5%" :
                                                            headCell.label === "Κατάσταση" ? "5%" :
                                                                headCell.label === "Πλατφόρμα" ? "5%" :
                                                                    "9%",
                                            cursor: headCell.disableSorting ? 'auto' : 'pointer'
                                        }}
                                        onClick={headCell.disableSorting || ticketsLoading ? null : () => requestSort(headCell.id)}

                                    >
                                        {headCell.disableSorting ? (
                                            headCell.label
                                        ) : (
                                            <TableSortLabel
                                                active={Boolean(getColumnSortDirection(headCell.id))}
                                                direction={getColumnSortDirection(headCell.id) || 'asc'}
                                                disabled={ticketsLoading}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {status === "loading" ? (
                                <TableRow style={{height: "calc(100vh - 64px)"}}>
                                    <TableCell colSpan={headCells.length + (isUserTeamLeaderOrSupervisor ? 1 : 0)}
                                               style={{textAlign: "center"}}>
                                        <CircularProgress/>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                sortedRows.map((ticket, index) => {
                                    const isItemSelected = selectedCheckboxes.some((row) => row.id === ticket.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={ticket.id}
                                            selected={isItemSelected}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "transparent",
                                                '&&:hover': {
                                                    backgroundColor: "#e9e9e9",
                                                },
                                            }}
                                            onDoubleClick={() => handleDoubleClick(ticket)}
                                        >
                                            {isUserTeamLeaderOrSupervisor && (

                                                <TableCell>
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: 0,
                                                        width: "100%"
                                                    }}>
                                                        {/* <Box>{ListTicketCurrentPage * size + index + 1}</Box> */}
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            disabled={isCheckboxDisabled(ticket)}
                                                            onChange={() => handleSelectClick(ticket)}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            )}
                                            <TableCell sx={{width: "5%"}} align="center">
                                                {ticket.sourceApplication.name === 'ticketing' ? <PhoneIcon/> : null}
                                                {ticket.sourceApplication.name === 'web ticketing' ?
                                                    <PublicIcon/> : null}
                                            </TableCell>

                                            {/* Render table cells for each data column */}
                                            <TableCell sx={{width: "15%"}} align='center'>{ticket.uuid}</TableCell>
                                            <TableCell sx={{width: "13%"}} align='center'>
                                                {new Intl.DateTimeFormat("el-GR", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                }).format(new Date(ticket.createdAt))}
                                            </TableCell>
                                            <TableCell sx={{width: "4%"}}
                                                       align='center'>{ticket.ticketStatus.name}</TableCell>
                                            <TableCell sx={{width: "9%"}}>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "100%"
                                                }}>
                                                    {ticket.ticketFlagCollection && ticket.ticketFlagCollection.length > 0 ? (
                                                        ticket.ticketFlagCollection.map((flag, index) => {
                                                            const {color, icon: Icon} = getFlagColor(flag.code);
                                                            if (index % 2 === 0 && ticket.ticketFlagCollection[index + 1]) {
                                                                const nextFlag = ticket.ticketFlagCollection[index + 1];
                                                                const {
                                                                    color: nextColor,
                                                                    icon: NextIcon
                                                                } = getFlagColor(nextFlag.code);
                                                                return (
                                                                    <Box key={index} sx={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "center",
                                                                        gap: 0.5,
                                                                        width: "100%"
                                                                    }}>
                                                                        <Tooltip title={flag.name}>
                                                                            <Avatar sx={{backgroundColor: color}}>
                                                                                {Icon ? <Icon sx={{color: "white"}}/> :
                                                                                    <Box>No Icon</Box>}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                        <Tooltip title={nextFlag.name}>
                                                                            <Avatar sx={{backgroundColor: nextColor}}>
                                                                                {NextIcon ?
                                                                                    <NextIcon sx={{color: "white"}}/> :
                                                                                    <Box>No Icon</Box>}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Box>
                                                                );
                                                            } else if (index % 2 === 0) {
                                                                return (
                                                                    <Box key={index} sx={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "center",
                                                                        gap: 0.5,
                                                                        width: "100%"
                                                                    }}>
                                                                        <Tooltip title={flag.name}>
                                                                            <Avatar sx={{backgroundColor: color}}>
                                                                                {Icon ? <Icon sx={{color: "white"}}/> :
                                                                                    <Box>No Icon</Box>}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Box>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    ) : (
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%"
                                                        }}>
                                                            <Typography sx={{textAlign: "center"}} variant="body2">Χωρίς
                                                                Flags</Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </TableCell>
                                            {/* Render beneficiary name */}
                                            <TableCell sx={{width: "14%"}} align='center'>
                                                {ticket.beneficiary &&
                                                (ticket.beneficiary.firstName ||
                                                    ticket.beneficiary.lastName)
                                                    ? `${ticket.beneficiary.firstName || ""} ${ticket.beneficiary.lastName || ""
                                                    }`.trim()
                                                    : "Δεν υπάρχει Ονοματεπώνυμο"}
                                            </TableCell>
                                            <TableCell sx={{width: "7%"}} align='center'>
                                                {ticket.thematic
                                                    ? ticket.thematic.carrier.title
                                                    : "Δεν υπάρχει Φορέας"}
                                            </TableCell>
                                            <TableCell sx={{width: "5%"}} align='center'>
                                                {ticket.assigneeUser &&
                                                ticket.assigneeUser.roleCollection &&
                                                ticket.assigneeUser.roleCollection.length > 0
                                                    ? ticket.assigneeUser.roleCollection.map(
                                                        (role, index) => {
                                                            return index !== 0
                                                                ? " " + role.roleName.split("_")[1]
                                                                : role.roleName.split("_")[1];
                                                        }
                                                    )
                                                    : "Δεν υπάρχει Επίπεδο"}
                                            </TableCell>


                                            <TableCell sx={{width: "20%"}} align='center'>
                                                <div style={{
                                                    overflow: "hidden",
                                                    wordBreak: "break-word",
                                                    maxWidth: "100%"
                                                }}>
                                                    {ticket.assigneeUser &&
                                                    (ticket.assigneeUser.firstName || ticket.assigneeUser.lastName)
                                                        ? `${ticket.assigneeUser.firstName || ""} ${ticket.assigneeUser.lastName || ""}`.trim()
                                                        : "Δεν υπάρχει Ανάθεση"}
                                                </div>
                                            </TableCell>


                                            <TableCell sx={{width: "9%"}} align='center'>
                                                <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => handleShowTicket(ticket.id)}
                                                        sx={{
                                                            borderRadius: 20,
                                                            backgroundColor: '#2e7d32 !important',
                                                            textTransform: 'none',
                                                            '&:disabled': {
                                                                backgroundColor: 'grey',
                                                            },
                                                        }}
                                                    >
                                                        <strong>Εμφάνιση</strong>
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        disabled={!canEditFlag[ticket.id]}
                                                        onClick={() => handleEditTicket(ticket.id)}
                                                        sx={{
                                                            borderRadius: 20,
                                                            backgroundColor: '#ed6c02 !important',
                                                            textTransform: 'none',
                                                            '&:disabled': {
                                                                backgroundColor: '#d7d7d7 !important',
                                                            },
                                                        }}
                                                    >
                                                        <strong>Επεξεργασία</strong>
                                                    </Button>

                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            )}


                        </TableBody>
                    </Table>

                    <MaterialPagination
                        currentPage={ListTicketCurrentPage}
                        totalPages={ListTicketTotalPages}
                        setPageAction={setListTicketCurrentPage}
                        ref={tableTopRef}/>
                </TableContainer>
            </Box>

        </Box>
    );
}

export default TicketList;
{/* <FormControl variant="standard" sx={{ width: 120, marginTop: 2 }}>
          <InputLabel htmlFor="size-select">Αριθμός Tickets προς εμφάνιση</InputLabel>
          <Select
            label="Αριθμός Tickets προς εμφάνιση"
            id="size-select"
            value={size}

            onChange={handleSizeChange}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl> */
}
