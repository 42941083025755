import React, {useState, useEffect} from 'react';
import {Backdrop, Box, CircularProgress, List, ListItem, TablePagination, Typography} from '@mui/material';
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import {useDispatch, useSelector} from "react-redux";
import {setTickets} from "store/userAssignmentSlice";
import {showMessage} from "store/messageSlice";
import {removeUserFromTeams} from "app/Api Calls/AddRemoveUserTeams";

const TicketSearchStep = ({user, onNoTicketsFound}) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [searchAttempted, setSearchAttempted] = useState(false);
    const dispatch = useDispatch();
    const {tickets, selectedTeam} = useSelector((state) => state.userAssignmentSlice)

    useEffect(() => {
        handleSearchTickets();
    }, [user.id, selectedTeam]);

    const handleSearchTickets = async (page = 0, size = 100) => {
        setLoading(true);
        setSearchAttempted(true);
        try {
            const requestBody = {
                title: null,
                fromDate: null,
                toDate: null,
                ticketFlg: null,
                priorityFlg: null,
                representativeFlg: null,
                followUpFlg: null,
                applicationFlg: null,
                beneficiaryId: null,
                representativeId: null,
                thematicId: null,
                ticketStatusId: 4,
                assigneeUserId: user?.id,
                uuid: null,
                groupId: null,
                whoIsCreated: null,
                carrierId: null,
                sourceApplicationId: null,
                teamId: selectedTeam?.id,
                orderDto: [
                    {
                        orderByColumn: "createdAt",
                        ordrerOrientation: "desc"
                    }
                ],
                fromGroupRoleId: null
            };
            const response = await searchTickets(page, size, requestBody, {limits: false});
            if (response.totalElements === 0) {
                if (!selectedTeam) {
                    const message = `Ο χρήστης δεν έχει ανατεθειμένα ticket${selectedTeam ? ` στην ομάδα ${selectedTeam.name}` : ""}.`;
                    dispatch(showMessage({
                        message: message,
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    }));
                    onNoTicketsFound(user.id, !user.isEnabled);
                } else {
                    await removeUserFromTeams(user.id, [selectedTeam.id]);
                    dispatch(showMessage({
                        message: "O χρήστης δεν έχει ανατεθειμένα tickets",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    }));
                    dispatch(showMessage({
                        message: "O χρήστης αφαιρέθηκε απο την ομάδα επιτυχώς",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    }));
                    onNoTicketsFound();
                }
            }
            dispatch(setTickets(response.elements));
            setCurrentPage(response.currentPage);
            setTotalElements(response.totalElements);
        } catch (error) {
            console.error('Failed to search for tickets:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChangePage = (event, newPage) => {
        handleSearchTickets(newPage);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" position="relative">
            {loading && (
                <Backdrop open={true}
                          style={{zIndex: 9999, color: '#fff', backgroundColor: 'rgba(255, 255, 255, 0.0)'}}>
                    <CircularProgress color="primary"/>
                </Backdrop>
            )}
            {!loading && (
                <>
                    {tickets?.length > 0 ? (
                        <>
                            <Typography align="center">Ο χρήστης έχει {totalElements} tickets ανοιχτά ανατεθειμένα επάνω
                                του {selectedTeam && ` στην ομάδα ${selectedTeam.name}`}.</Typography>
                            <TablePagination
                                component="div"
                                count={totalElements}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                rowsPerPage={100}
                                rowsPerPageOptions={[100]}
                                labelDisplayedRows={({from, to, count}) => `${from}-${to} από ${count}`}
                                labelRowsPerPage="Εισιτήρια ανά σελίδα:"
                            />
                            <List>
                                {tickets.map((ticket, index) => (
                                    <ListItem key={index}>{ticket.uuid}</ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        searchAttempted && <Typography align="center">Δεν υπάρχουν tickets</Typography>
                    )}
                </>
            )}
        </Box>
    );
};

export default TicketSearchStep;
