import React from "react";
import {
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const SearchForm = ({
                        search,
                        searchType,
                        error,
                        loading,
                        isSearchDisabled,
                        handleChange,
                        handleSearchTypeChange,
                        handleSearch,
                        handleClearSearch,
                        getHelperText,
                    }) => {
    return (
        <Box display="flex" alignItems="baseline" marginBottom={2} sx={{ position: 'sticky', top: 0, zIndex: 1, paddingBottom: '10px', paddingTop: '10px' }}>
            <Box>
                <FormControl fullWidth>
                    <InputLabel>Δικαιούχος</InputLabel>
                    <Select
                        sx={{ width: "120px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: 'white' }}
                        value={searchType || ""}
                        onChange={handleSearchTypeChange}
                        label="Δικαιούχος"
                        size="small"
                        disabled={loading}
                    >
                        <MenuItem value={"ΑΦΜ"}>ΑΦΜ</MenuItem>
                        <MenuItem value={"ΑΜΚΑ"}>ΑΜΚΑ</MenuItem>
                        <MenuItem value={"Επώνυμο"}>Επώνυμο</MenuItem>
                        <MenuItem value={"Τηλέφωνο"}>Τηλέφωνο</MenuItem>
                        <MenuItem value={"Κινητό"}>Κινητό</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box flexGrow={3}>
                <TextField
                    value={search || ""}
                    onChange={handleChange}
                    variant="outlined"
                    helperText={getHelperText()}
                    error={error && searchType === ""}
                    disabled={loading}
                    FormHelperTextProps={{
                        style: { color: getHelperText() ? "red" : "inherit" },
                    }}
                    sx={{backgroundColor: 'white'}}
                    size="small"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch} disabled={loading}>
                                    {!isSearchDisabled && (
                                        <SearchIcon
                                            sx={{
                                                backgroundColor: "#003476 !important",
                                                color: "#fff !important",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    )}
                                </IconButton>

                                {search && (
                                    <IconButton onClick={handleClearSearch} disabled={loading}>
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default SearchForm;
