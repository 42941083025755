import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCarriers,
  setSelectedCarrier,
  setSelectedThematic,
  setSelectedSubthematic,
  setSelectedSubSubthematic,
  setResults,
} from 'store/knowledgeBasePageSlice';
import CategoriesDropdown from '../CategoriesDropdown';
import settingsConfig from "app/configs/settingConfig";

function CarrierSelector({isCreatePage}) {
  const dispatch = useDispatch();
  const carriers = useSelector((state) => state.knowledgeBasePageSlice.carriers);
  const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const currentUserRole = useSelector((state) => state.user.roleName);

  useEffect(() => {
    if (!carriers.length) {
      dispatch(fetchCarriers());
    }
  }, [dispatch, carriers]);

  const handleChange = (selectedOption) => {
    dispatch(setSelectedCarrier(selectedOption));
    dispatch(setSelectedThematic(null));
    dispatch(setSelectedSubthematic(null));
    dispatch(setSelectedSubSubthematic(null));
    if (selectedOption === null) {
      dispatch(setResults([]));
    }
  };

  // Check if the current user is a supervisor
  const isSupervisor = useMemo(() =>
      settingsConfig.roles.supervisor.some(role => currentUserRole?.includes(role)), [currentUserRole]);

  const isDropdownDisabled = isCreatePage ? false : (!isSupervisor || (activeStatus && activeStatus.id === 5));


  return (
      <CategoriesDropdown
          options={carriers.map((carrier) => ({
            value: carrier.id,
            label: carrier.title,
            useGeo: carrier.useGeo
          }))}
          value={selectedCarrier}
          isDisabled={isDropdownDisabled}
          onChange={handleChange}
          placeholder="Φορέας"
          backgroundColor="white"
      />
  );
}

export default CarrierSelector;
